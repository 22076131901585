import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IAutocompleteOption, IControlBadge } from '@shared/components/form-controls';
import { EDataTestIdKeys, getDataTestId } from '@shared/utils';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DropDownControlDirective } from '../../directives/drop-down-directive/drop-down-form-control.directive';

@Component({
    selector: 'mv-autocomplete-form-control',
    templateUrl: './autocomplete-form-control.component.html',
    styleUrls: ['./autocomplete-form-control.component.scss'],
    host: { class: 'mv-autocomplete-form-control' },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteFormControlComponent implements OnInit, OnDestroy {
    @Input() label: string = '';
    @Input() testId: string = '';
    @Input() control: FormControl = new FormControl<string>('');
    @Input() disabled: boolean = false;
    @Input() loading: boolean = false;

    @Input() badge: IControlBadge | null = null;

    @Input() customFilterFn?: (searchValue: string, options: IAutocompleteOption[]) => IAutocompleteOption[];

    @Input() withCustomTemplate = false;

    @Input() optionsContainerStyles: { [key: string]: string } = {};

    @Input() set options(options: IAutocompleteOption[]) {
        this.optionsItems = options;

        this.optionsDataTestIds = options.map((option) => {
            return getDataTestId(EDataTestIdKeys.OPTION, option.value);
        });

        this.displayedOptions = this.filterDisplayedOptions(this.control.value);

        this.optionsBadges.clear();

        options?.forEach((option) => {
            if (!!option.badge) {
                this.optionsBadges.set(option.value, option.badge);
            }
        });
    }

    @Output() optionSelected = new EventEmitter<IAutocompleteOption>();

    @ViewChild(DropDownControlDirective) dropdownControlDirective!: DropDownControlDirective;

    public optionsItems: IAutocompleteOption[] = [];
    public displayedOptions: IAutocompleteOption[] = [];
    public isFocus: boolean = false;

    private optionsBadges = new Map<any, IControlBadge>();

    // Data Test IDs
    public autocompleteDataTestId: string;
    public resetButtonDataTestId: string;
    public optionsDataTestIds: string[];

    private destroy$ = new Subject<void>();

    public get isDisabled(): boolean {
        return this.disabled || this.control.disabled;
    }

    public get optionBadge(): IControlBadge | null {
        return this.optionsBadges.get(this.control.value) || this.badge || null;
    }

    // не удалять, плохо работает индексация,
    // используется при обращении к elementRef автокомплита
    public get isDropDownOpened(): boolean {
        return !!this.dropdownControlDirective?.isOptionsOpened;
    }

    public get isRequired(): boolean {
        return this.control.hasValidator(Validators.required);
    }

    public get isError(): boolean {
        return this.control.invalid && this.control.touched && !this.disabled;
    }

    constructor(private cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.setDataTestIds();
        this.subcribeValueChanges();
        this.subscribeStatusChanges();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onFocus(): void {
        this.isFocus = true;
        this.dropdownControlDirective.showOptions();
    }

    public onBlur(): void {
        this.isFocus = false;
    }

    public onCloseOptions(): void {
        const option = this.optionsItems.find(option => option.value === this.control.value);
        const isOwnOption = this.control.value && !option;

        if (isOwnOption) {
            this.control.setErrors({ notAutocompleteOption: true });
        }
    }

    public onSelectOption(option: IAutocompleteOption): void {
        this.optionSelected.emit(option);

        this.control.markAsDirty();
        this.control.setValue(option.value);
    }

    public clearInput($event: Event): void {
        $event.stopPropagation();
        this.displayedOptions = [];
        this.control.setValue('');
    }

    private setDataTestIds(): void {
        const { AUTOCOMPLETE, RESET_BUTTON } = EDataTestIdKeys;

        this.autocompleteDataTestId = getDataTestId(AUTOCOMPLETE, this.testId || this.label);
        this.resetButtonDataTestId = getDataTestId(RESET_BUTTON, this.testId || this.label);
    }

    private subscribeStatusChanges(): void {
        this.control.statusChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.cdRef.markForCheck());
    }

    private subcribeValueChanges(): void  {
        this.control.valueChanges
            .pipe(
                map((value) => this.filterDisplayedOptions(value)),
                takeUntil(this.destroy$)
            )
            .subscribe((filteredOptions) => {
                this.displayedOptions = filteredOptions;
                this.cdRef.markForCheck();
            });
    };

    private filterDisplayedOptions = (searchValue: string): IAutocompleteOption[] => {
        if (this.customFilterFn) {
            return this.customFilterFn(searchValue, this.optionsItems);
        }

        if (!searchValue) return [...this.optionsItems];

        return this.optionsItems.filter((option) => {
            return option.value.toLowerCase().includes(searchValue.trim().toLowerCase());
        });
    };
}
