import { IPageResult } from '@shared/lib/med-common/interfaces';

export interface IApiPrescription
    extends Omit<IPrescriptionFormatted, 'prescriberid' | 'pharmacyId'> {
    doctor: IPrescriptionDoctorFormatted | null;
    pharmacy: IPrescriptionPharmacyFormatted | null;
}

export interface IPrescriptionPharmacyFormatted {
    id: number;
    storeName: string;
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zipcode: string;
    primaryPhone: string;
    primaryFax: string;
}

export interface IPrescriptionDoctorFormatted {
    id: number;
    firstName: string;
    middleName: string;
    lastName: string;
    prefix?: string;
}

export interface IPrescriptionFormatted {
    id: number;
    displayName: string;
    prescriberId: number;
    pharmacyId: number;
    firstPrescriptionDiagnosis: FirstPrescriptionDiagnosis;
    genericDrugName: string;
    genericProductName: string;
    drugClassification: string;
    status: EApiPrescriptionStatuses;
    medicationStatus: EApiMedicationStatuses | EApiMedicationStatusesV2;
    writtenDate: Date;
    effectiveDate: Date;
    dateInactive: Date;
    directions: string;
    strength: string;
    refills: string;
    quantity: string;
    daysSupply: number;
    isUrgent: boolean;
    pharmacyNotes: string;
    dispenseUnitDescription: string;
    noSubstitutions: boolean;
    schedule: string;
    lexiDrugSynId: number;
    lexiSynonymTypeId: number;
    dispenseUnitId: number;
    lexiGenProductId: number;
    ndc: string;
}

export interface FirstPrescriptionDiagnosis {
    PrimaryDiagnosis: {
        DiagnosisId: number;
        DiagnosisCode: string;
        DiagnosisDescription: string;
    };
}

export interface IApiPrescriptionsResponse {
    pageResult?: IPageResult;
    prescriptions: IApiPrescription[];
}

export enum EApiPrescriptionStatuses {
    ENTERED = 'Entered',
    PRINTED = 'Printed',
    SENDING = 'Sending',
    E_RX_SENT = 'eRxSent',
    FAX_SENT = 'FaxSent',
    ERROR = 'Error',
    DELETED = 'Deleted',
    REQUESTED = 'Requested',
    EDITED = 'Edited',
    EPCS_ERROR = 'EpcsError',
    EPCS_SIGNED = 'EpcsSigned',
    READY_TO_SIGN = 'ReadyToSign',
    PHARMACY_VERIFIED = 'PharmacyVerified',
}

export enum EApiMedicationStatuses {
    FULL_FILL = 'Medication filled',
    PARTIAL_FILL = 'Medication partially filled',
    NO_FILL = 'Medication not filled',
    ACTIVE = 'Medication active',
    DISCONTINUED = 'Medication discontinued',
    DELETED = 'Medication deleted',
    COMPLETED = 'Medication completed',
    CANCEL_REQUESTED = 'Medication cancel requested',
    CANCEL_PENDING = 'Medication cancel pending',
    CANCELLED = 'Medication cancelled',
    CANCEL_DENIED = 'Medication cancel denied',
    CHANGED = 'Medication changed',
}

export enum EApiMedicationStatusesV2 {
    FULL_FILL = 'FullFill',
    PARTIAL_FILL = 'PartialFill',
    NO_FILL = 'NoFill',
    ACTIVE = 'Active',
    DISCONTINUED = 'Discontinued',
    DELETED = 'Deleted',
    COMPLETED = 'Completed',
    CANCEL_REQUESTED = 'CancelRequested',
    CANCEL_PENDING = 'CancelPending',
    CANCELLED = 'Cancelled',
    CANCEL_DENIED = 'CancelDenied',
    CHANGED = 'Changed',
}

export enum EUDPrescriptionStatuses {
    PRESCRIPTION_PREPARATION = 'Prescription preparation',
    CANCELLED = 'Cancelled',
    SENT_TO_PHARMACY = 'Sent to pharmacy',
    PHARMACY_PROCESSING = 'Pharmacy processing',
    READY_TO_PICK_UP = 'Ready to pick up',
    PARTIAL_FILL_TO_PICK_UP = 'Get partial fill',
    PHARMACY_ISSUE = 'Pharmacy issue',
    COMPLETED = 'Completed',
}

export const PrescriptionMedicationStatusesDivision: {
    [key in EUDPrescriptionStatuses]: (
        | EApiPrescriptionStatuses
        | EApiMedicationStatuses
        | EApiMedicationStatusesV2
    )[];
} = {
    [EUDPrescriptionStatuses.PRESCRIPTION_PREPARATION]: [
        EApiPrescriptionStatuses.ENTERED,
        EApiPrescriptionStatuses.PRINTED,
        EApiPrescriptionStatuses.READY_TO_SIGN,
        EApiPrescriptionStatuses.REQUESTED,
        EApiPrescriptionStatuses.EDITED,
    ],
    [EUDPrescriptionStatuses.CANCELLED]: [EApiPrescriptionStatuses.DELETED],
    [EUDPrescriptionStatuses.SENT_TO_PHARMACY]: [
        EApiPrescriptionStatuses.SENDING,
        EApiPrescriptionStatuses.E_RX_SENT,
        EApiPrescriptionStatuses.FAX_SENT,
        EApiPrescriptionStatuses.ERROR,
        EApiPrescriptionStatuses.EPCS_ERROR,
        EApiPrescriptionStatuses.EPCS_SIGNED,
        EApiPrescriptionStatuses.READY_TO_SIGN,
    ],
    [EUDPrescriptionStatuses.PHARMACY_PROCESSING]: [
        EApiPrescriptionStatuses.PHARMACY_VERIFIED,
        EApiMedicationStatuses.ACTIVE,
        EApiMedicationStatuses.CANCEL_REQUESTED,
        EApiMedicationStatuses.CANCEL_PENDING,
        EApiMedicationStatuses.CANCEL_DENIED,
        EApiMedicationStatuses.CHANGED,
        EApiMedicationStatusesV2.ACTIVE,
        EApiMedicationStatusesV2.CANCEL_REQUESTED,
        EApiMedicationStatusesV2.CANCEL_PENDING,
        EApiMedicationStatusesV2.CANCEL_DENIED,
        EApiMedicationStatusesV2.CHANGED,
    ],
    [EUDPrescriptionStatuses.READY_TO_PICK_UP]: [
        EApiMedicationStatuses.FULL_FILL,
        EApiMedicationStatusesV2.FULL_FILL,
    ],
    [EUDPrescriptionStatuses.PARTIAL_FILL_TO_PICK_UP]: [
        EApiMedicationStatuses.PARTIAL_FILL,
        EApiMedicationStatusesV2.PARTIAL_FILL,
    ],
    [EUDPrescriptionStatuses.PHARMACY_ISSUE]: [
        EApiMedicationStatuses.NO_FILL,
        EApiMedicationStatuses.DISCONTINUED,
        EApiMedicationStatuses.DELETED,
        EApiMedicationStatuses.CANCELLED,
        EApiMedicationStatusesV2.NO_FILL,
        EApiMedicationStatusesV2.DISCONTINUED,
        EApiMedicationStatusesV2.DELETED,
        EApiMedicationStatusesV2.CANCELLED,
    ],
    [EUDPrescriptionStatuses.COMPLETED]: [
        EApiMedicationStatuses.COMPLETED,
        EApiMedicationStatusesV2.COMPLETED,
    ],
};
