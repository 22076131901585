import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { captureException } from '@sentry/browser';
import { createCustomLog } from '@shared/utils';
import { INITIALIZER, OPTIONS, SentryModuleOptions } from './sentry.config';

const CHUNK_FAILED_ERROR = /Loading chunk \d+ failed/;

@Injectable()
class SentryErrorHandler implements ErrorHandler {
    constructor(@Inject(OPTIONS) private readonly options: SentryModuleOptions) {}

    public handleError(error: any): void {
        if (CHUNK_FAILED_ERROR.test(error.message)) {
            createCustomLog('Chunk Error: Application will be reloaded');
            window.location.reload();
            return;
        }

        if (this.options.enabled) {
            captureException(error.originalError || error);
        }
    }
}

export const SentryErrorHandlerProvider = {
    provide: ErrorHandler,
    useClass: SentryErrorHandler,
    deps: [OPTIONS, INITIALIZER],
};
