<div class="input-control">
    <div
        class="input-control-container"
        [class.disabled]="isDisabled"
        [class.error]="isError"
        (click)="onFocus()"
    >
        <div
            *ngIf="!!label"
            class="input-control__label"
            [class.floated]="isLabelFloating"
            [class.required]="isRequired"
        >
            {{ label }}
        </div>

        <span *ngIf="prefix" class="input-control__prefix">
            {{ prefix }}
        </span>

        <input
            #input
            class="input-control__value"
            [class.no-label]="!label"
            [type]="type"
            [mask]="mask"
            [dropSpecialCharacters]="isSpecialSymbolShouldBeDropped"
            [thousandSeparator]="thousandSeparator"
            [separatorLimit]="separatorLimit"
            [decimalMarker]="decimalMarker"
            [formControl]="control"
            [attr.inputmode]="inputMode"
            [attr.autocomplete]="autocompleteAttrName"
            [placeholder]="placeholder"
            [attr.data-test-id]="inputDataTestId"
            (focus)="onFocus()"
            (blur)="onBlur()"
        />

        <mv-control-badge
            *ngIf="badge"
            class="input-control__badge state-icon"
            [badge]="badge"
        ></mv-control-badge>
    </div>
    <mv-control-error-message
        [errors]="control.errors"
        [isError]="isError"
    ></mv-control-error-message>
</div>
