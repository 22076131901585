<div class="autocomplete-form-control" (click)="input.focus()">
    <div
        controlDropDownOptions
        class="autocomplete-form-control-container"
        [class.disabled]="isDisabled"
        [class.error]="isError"
        [optionsTemplate]="optionsTemplate"
        [isOptionsAlwaysVisible]="true"
        (closeOptions)="onCloseOptions()"
    >
        <!-- Label -->
        <div
            *ngIf="label"
            class="autocomplete-form-control__label"
            [class.floated]="isFocus || control.value"
            [class.required]="isRequired"
        >
            {{ label }}
        </div>

        <!--  Input  -->
        <input
            [class.no-label]="!label"
            [formControl]="control"
            [attr.disabled]="isDisabled || null"
            [attr.data-test-id]="autocompleteDataTestId"
            (focus)="onFocus()"
            (blur)="onBlur()"
            #input
            class="autocomplete-form-control__value"
        />

        <!-- Badge -->
        <mv-control-badge
            *ngIf="optionBadge"
            class="autocomplete-form-control__badge state-icon"
            [badge]="optionBadge"
        ></mv-control-badge>

        <!-- Reset Icon -->
        <mv-google-symbol-icon
            *ngIf="!loading && control.value && !isDisabled"
            [size]="20"
            class="autocomplete-form-control__icon state-icon"
            [attr.data-test-id]="resetButtonDataTestId"
            (click)="clearInput($event)"
        >
            close
        </mv-google-symbol-icon>

        <!--  Loading Icon -->
        <mv-google-symbol-icon
            *ngIf="loading"
            class="autocomplete-form-control__loading state-icon"
            [size]="20"
        >
            progress_activity
        </mv-google-symbol-icon>

        <!-- Autocomplete Options -->
        <ng-template #optionsTemplate>
            <div
                class="autocomplete-form-control-options"
                [ngStyle]="optionsContainerStyles"
                [class.no-items]="!displayedOptions?.length && !withCustomTemplate"
            >
                <div
                    *ngFor="let displayedOption of displayedOptions; index as i"
                    class="autocomplete-form-control__option"
                    [attr.data-test-id]="optionsDataTestIds[i]"
                    (click)="onSelectOption(displayedOption)"
                >
                    <div>{{ displayedOption.value }}</div>

                    <mv-control-badge
                        class="autocomplete-form-control__badge"
                        *ngIf="displayedOption.badge"
                        [badge]="displayedOption.badge"
                    ></mv-control-badge>
                </div>
                <div *ngIf="withCustomTemplate" class="autocomplete-form-control__custom-template">
                    <ng-content select="[mv-custom-template]"></ng-content>
                </div>
            </div>
        </ng-template>
    </div>
    <mv-control-error-message
        [isError]="isError"
        [errors]="control.errors"
    ></mv-control-error-message>
</div>
