<div
        class="snackbar"
        [class.without-title]="!data?.title"
        [attr.data-test-id]="dataTestId"
>
    <div class="snackbar__icon" [ngClass]="data.type">{{ icon }}</div>
    <div class="snackbar-content">
        <div *ngIf="data?.title" class="snackbar-content__title">{{ data.title }}</div>
        <div class="snackbar-content__message">{{ data?.message }}</div>
        <div *ngIf="data?.actionFn" class="snackbar-content-actions">
            <mv-button [buttonSize]="EButtonSize.XS" (click)="onAction()">
                {{ data?.actionName ?? 'Confirm' }}
            </mv-button>
        </div>
    </div>
    <mv-google-symbol-icon class="snackbar__close" (click)="onClose()">close</mv-google-symbol-icon>
</div>
