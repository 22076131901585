export enum InputMaskEnum {
    ONLY_DIGITS = '0*',
    INT_FLOAT_NUMBERS = 'separator.2',
    ZIP_CODE = '00000||00000-0000',
    USA_PHONE = '(000) 000-0000',
    VERIFICATION_CODE = '0000',
    DOSESPOT_OTP = '000000',
    MEDICATION_QUANTITY = 'separator.5',
    BLOOD_PRESSURE = '0*/00?0',
    SOCIAL_SECURITY_NUMBER = '000-00-0000'
}
