import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IControlBadge } from '@shared/components/form-controls';

@Component({
    selector: 'mv-control-badge',
    templateUrl: './control-badge.component.html',
    styleUrls: ['./control-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlBadgeComponent {
    @Input() badge: IControlBadge;
}
