import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ActiveAppointmentService } from '../active-appointment/active-appointment.service';
import { GoogleAnalyticService } from '@patient/app/shared/modules/google/google-analytic.service';
import {
    EventActionsType,
    EventSourceType,
    EventTypes,
} from '@patient/app/shared/modules/google/google-analytic.utils';
import { ELocalStorageKeys, EQueryParameters } from '../../interfaces/app-parameters';

@Injectable({ providedIn: 'root' })
export class ParametersService {
    constructor(
        private cookieService: CookieService,
        private appointmentService: ActiveAppointmentService,
        private googleAnalyticService: GoogleAnalyticService
    ) {}

    public initAppParameters(): void {
        const params = this.getQueryParams();

        const serviceQueryValue: string[] | null = this.getMultipleQueryParameter(
            EQueryParameters.SERVICE
        );
        const promoCodeValue: string | null = params[EQueryParameters.PROMO_CODE];
        const autoSignInEmail: string | null = params[EQueryParameters.AUTO_SIGN_IN_EMAIL];
        const autoSignInPhone: string | null = params[EQueryParameters.AUTO_SIGN_IN_PHONE];
        const nextVisitAction: string | null = params[EQueryParameters.NEXT_VISIT_ACTION];
        const intakeReminderAttempt: string | null = params[EQueryParameters.INTAKE_REMINDER_ATTEMPT];
        const intakeReminderSource: string | null = params[EQueryParameters.INTAKE_REMINDER_SOURCE];

        if (promoCodeValue) {
            localStorage.setItem(ELocalStorageKeys.PROMO_CODE, promoCodeValue);
        }

        if (serviceQueryValue) {
            localStorage.setItem(ELocalStorageKeys.SELECTED_SERVICES, serviceQueryValue.join(','));
            this.removeQueryParam(EQueryParameters.SERVICE);
        }

        let reschedule: string | null = params[EQueryParameters.RESCHEDULE];
        if (reschedule) {
            this.appointmentService.setReschedule(true);
        }

        if (autoSignInEmail) {
            localStorage.setItem(ELocalStorageKeys.AUTO_SIGN_IN_EMAIL, autoSignInEmail);
            this.removeQueryParam(EQueryParameters.AUTO_SIGN_IN_EMAIL);
        }

        if (autoSignInPhone) {
            localStorage.setItem(ELocalStorageKeys.AUTO_SIGN_IN_PHONE, autoSignInPhone.substring(1));
            this.removeQueryParam(EQueryParameters.AUTO_SIGN_IN_PHONE);
        }

        if (nextVisitAction) {
            this.handleNextVisitLogs(nextVisitAction);
            this.removeQueryParam(EQueryParameters.NEXT_VISIT_ACTION);
        }

        if (intakeReminderAttempt) {
            localStorage.setItem(ELocalStorageKeys.INTAKE_REMINDER_ATTEMPT, intakeReminderAttempt);
            this.removeQueryParam(EQueryParameters.INTAKE_REMINDER_ATTEMPT);
        }

        if (intakeReminderSource) {
            localStorage.setItem(ELocalStorageKeys.INTAKE_REMINDER_SOURCE, intakeReminderSource);
            this.removeQueryParam(EQueryParameters.INTAKE_REMINDER_SOURCE);
        }

        this.setUTMsInLocalStorage();
    }

    public setUTMsInLocalStorage(): void {
        const params = this.getQueryParams();

        let gClientId: string | null = params[EQueryParameters.G_CLIENT_ID];
        let utmSource: string | null = params[EQueryParameters.UTM_SOURCE];
        let utmMedium: string | null = params[EQueryParameters.UTM_MEDIUM];
        let utmCampaign: string | null = params[EQueryParameters.UTM_CAMPAIGN];
        let utmContent: string | null = params[EQueryParameters.UTM_CONTENT];
        let utmTerm: string | null = params[EQueryParameters.UTM_TERM];

        if (!gClientId) gClientId = this.getGClientIdCookie();
        if (!utmSource) utmSource = this.getUtmCookieByName('utmcsr');
        if (!utmMedium) utmMedium = this.getUtmCookieByName('utmcmd');
        if (!utmCampaign) utmCampaign = this.getUtmCookieByName('utmccn');
        if (!utmContent) utmContent = this.getUtmCookieByName('utmcct');
        if (!utmTerm) utmTerm = this.getUtmCookieByName('utmctr');

        if (gClientId) localStorage.setItem(ELocalStorageKeys.G_CLIENT_ID, gClientId);
        if (utmSource) localStorage.setItem(ELocalStorageKeys.UTM_SOURCE, utmSource);
        if (utmMedium) localStorage.setItem(ELocalStorageKeys.UTM_MEDIUM, utmMedium);
        if (utmCampaign) localStorage.setItem(ELocalStorageKeys.UTM_CAMPAIGN, utmCampaign);
        if (utmContent) localStorage.setItem(ELocalStorageKeys.UTM_CONTENT, utmContent);
        if (utmTerm) localStorage.setItem(ELocalStorageKeys.UTM_TERM, utmTerm);
    }

    private getGClientIdCookie(): string | null {
        const gClientIdCookie = this.cookieService.get('_ga');
        const clientIdArray = gClientIdCookie ? gClientIdCookie.split('.') : null;

        return clientIdArray ? `${clientIdArray[2]}.${clientIdArray[3]}` : null;
    }

    private getUtmCookieByName(name: string): string | null {
        const utmCookieArray: string[] = this.cookieService.get('__utmzz')?.split('|') || [];

        const cookie = utmCookieArray.find((x) => x.split('=')[0] === name);

        return cookie ? cookie.split('=')[1] : null;
    }

    private getQueryParams(): { [k: string]: string } {
        const urlSearchParams = new URLSearchParams(window.location.search);

        return Object.fromEntries(urlSearchParams.entries());
    }

    private getMultipleQueryParameter(parameterName: string): string[] | null {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const resultObject: Record<string, string[]> = {};

        for (const [key, value] of urlSearchParams) {
            if (!resultObject[key]) {
                resultObject[key] = [value];
            } else {
                resultObject[key].push(value);
            }
        }

        return resultObject[parameterName];
    }

    private removeQueryParam(param: string): void {
        const url = new URL(window.location.href);

        url.searchParams.has(param) && url.searchParams.delete(param);

        window.history.replaceState({}, document.title, url.toString());
    }

    private handleNextVisitLogs(action: string): void {
        const [actionType, leadId] = action.split('-');

        this.googleAnalyticService.emitEvent(EventTypes.BUTTON_TAPPED, {
            action: EventActionsType.CONFIRM_APPOINTMENT,
            screen_name: EventSourceType.APPOINTMENT_COMPLETED,
            lead_id: +leadId,
        });
    }
}
