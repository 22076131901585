export enum ESelectBadgeColor {
    PRIMARY = 'primary',
    SUCCESS = 'success',
    NEUTRAL = 'neutral',
}

export interface IControlBadge {
    text: string,
    color: ESelectBadgeColor
}
