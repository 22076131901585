import { init, showReportDialog } from '@sentry/browser';
import { INITIALIZER, OPTIONS, SentryModuleOptions } from './sentry.config';

const sentryInitializer = (options: SentryModuleOptions): void => {
    if (!options.enabled) return;

    if (!options.dialog) {
        init(options.sentry);
        return;
    }

    options.sentry.beforeSend = (event): unknown => {
        if (event.exception) {
            showReportDialog(typeof options.dialog === 'object' ? options.dialog : undefined);
        }

        return event;
    };
};

export const SentryInitializerProvider = {
    provide: INITIALIZER,
    useFactory: sentryInitializer,
    deps: [OPTIONS],
};
