import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageOverlayModule } from '@patient/app/shared/components/page-overlay/page-overlay.module';
import { AddAccessTokenInterceptorProvider } from '@patient/app/shared/interceptors/add-access-token.interceptor';
import {
    GoogleAnalyticOptions,
    GoogleModule,
} from '@patient/app/shared/modules/google/google.module';
import { SnackbarService } from '@shared/components';
import { SentryModule, SentryModuleOptions } from '@shared/services/sentry';
import { environment as env } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';
import { NgxStripeModule } from 'ngx-stripe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BodyComponent } from './layout/body/body.component';
import { FooterComponent } from './layout/footer/footer.component';
import { AvatarMenuComponent } from './layout/header/avatar-menu/avatar-menu.component';
import { BurgerMenuComponent } from './layout/header/burger-menu/burger-menu.component';
import { HeaderComponent } from './layout/header/header.component';
import { PageInfoBlockComponent } from './layout/header/page-info-block/page-info-block.component';
import { ProgressBarComponent } from './layout/header/progress-bar/progress-bar.component';
import { LayoutComponent } from './layout/layout.component';
import { DocumentExampleListComponent } from './shared/components/document-example-list/document-example-list.component';
import { ExpansionPanelComponent } from './shared/components/expansion-panel/expansion-panel.component';
import { PlainSelectComponent } from './shared/components/plain-select/plain-select.component';

const isDevMode = env.environment === 'development';

const sentryConfig: SentryModuleOptions = {
    enabled: isDevMode ? false : env.patientSentry.enabled,
    sentry: {
        dsn: env.patientSentry.dsn,
        environment: env.environment,
        tracesSampleRate: env.patientSentry.tracesSampleRate,
        release: env.environment,
    },
    http: {
        enabled: env.patientSentry.http.enabled,
        message: env.patientSentry.http.defaultErrorHttpMessage,
    },
};

const googleAnalyticOptions: GoogleAnalyticOptions = {
    isAnalyticActive: !isDevMode,
    applicationId: (env.googleTagManager as Record<string, string>)[location.hostname],
    isAvailableInConsole: env.environment !== 'prod',
};

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        HeaderComponent,
        BodyComponent,
        LayoutComponent,
        AvatarMenuComponent,
        BurgerMenuComponent,
        PageInfoBlockComponent,
        ProgressBarComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SentryModule.forRoot(sentryConfig),
        NgxStripeModule.forRoot(env.stripeToken),
        GoogleModule.forRoot(googleAnalyticOptions),
        MatSnackBarModule,
        NgxMaskModule.forRoot(),
        AppRoutingModule,
        PageOverlayModule,
        MatIconModule,
        OverlayModule,
        MatDatepickerModule,
        MatNativeDateModule,
        PlainSelectComponent,
        ExpansionPanelComponent,
        DocumentExampleListComponent,
    ],
    providers: [CookieService, SnackbarService, AddAccessTokenInterceptorProvider],
    bootstrap: [AppComponent],
})
export class AppModule {}
