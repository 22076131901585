import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    Output,
    Renderer2,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';

@Directive({ selector: '[controlDropDownOptions]' })
export class DropDownControlDirective implements OnDestroy {
    @Input() optionsTemplate: TemplateRef<any>;

    // автокомплит не скрывается при клике на контрол
    private isAlwaysOpen: boolean = false;

    @Input() set isOptionsAlwaysVisible(isAlwaysOpen: boolean) {
        this.isAlwaysOpen = isAlwaysOpen;
    }

    @Output() openOptions = new EventEmitter<void>();
    @Output() closeOptions = new EventEmitter<void>();

    private optionsEl: HTMLElement | null = null;

    @HostListener('window:resize') resizeWindow() {
        if (!this.optionsEl) return;
        this.setStylesOptions();
    }

    @HostListener('document:click', ['$event.target'])
    clickOutside(targetElement: HTMLElement) {
        if (this.elRef.nativeElement.contains(targetElement)) return;
        if (!this.optionsEl) return;

        // skip if input is still focused (after copying text for example)
        if (document?.activeElement?.tagName === 'INPUT') return;

        this.hideOptions();
    }

    @HostListener('click') clickElement() {
        if (!this.optionsEl) {
            this.showOptions();
        } else if (!this.isAlwaysOpen && this.optionsEl) {
            this.hideOptions();
        }
    }

    private setStylesTimeout: number;

    public get isOptionsOpened(): boolean {
        return this.optionsEl !== null;
    }

    constructor(
        private elRef: ElementRef,
        private vcRef: ViewContainerRef,
        private renderer: Renderer2
    ) {}

    public showOptions(): void {
        if (this.vcRef.length) return;

        const embeddedView = this.vcRef.createEmbeddedView(this.optionsTemplate);

        if (embeddedView.rootNodes.length === 0) return;

        this.optionsEl = embeddedView.rootNodes[0];

        // add options container to document
        this.renderer.appendChild(document.body, this.optionsEl);
        // remove blink effect
        this.renderer.setStyle(this.optionsEl, 'top', 0);
        this.renderer.setStyle(this.optionsEl, 'opacity', 0);

        this.applyAfterRender(() => {
            this.setStylesOptions();
            this.addScrollListener();
            this.openOptions.emit();
        });
    }

    public hideOptions(): void {
        if (!this.optionsEl) return;

        this.renderer.removeChild(document.body, this.optionsEl);
        this.optionsEl = null;
        this.vcRef.clear();
        this.removeScrollListener();
        this.closeOptions.emit();
    }

    private distanceToScreenEnd = (rectEl: DOMRect): number => {
        return window.innerHeight - (rectEl.top + rectEl.height);
    };

    private setStylesOptions(): void {
        if (!this.optionsEl) return;

        const rectEl: DOMRect = this.elRef.nativeElement.getBoundingClientRect();
        const optionsElHeight = this.optionsEl.getBoundingClientRect().height;
        const offsetFromInput = 10;

        const hasBottomDistance = this.distanceToScreenEnd(rectEl) - offsetFromInput > optionsElHeight;
        const hasTopDistance = rectEl.top - optionsElHeight - offsetFromInput > 0;

        if (hasTopDistance || hasBottomDistance) {
            const top = hasBottomDistance
                ? rectEl.top + window.scrollY + rectEl.height + offsetFromInput
                : rectEl.top + window.scrollY - optionsElHeight - offsetFromInput;

            this.renderer.setStyle(this.optionsEl, 'top', top + 'px');
        } else {
            this.renderer.setStyle(this.optionsEl, 'top', rectEl.top + window.scrollY + 'px');
        }

        this.renderer.setStyle(this.optionsEl, 'left', rectEl.left + window.scrollX + 'px');
        this.renderer.setStyle(this.optionsEl, 'width', rectEl.width + 'px');
        this.renderer.setStyle(this.optionsEl, 'opacity', 1);
    }

    private addScrollListener() {
        window.addEventListener('scroll', this.setStylesOptions.bind(this), true);
    }

    private removeScrollListener() {
        window.removeEventListener('scroll', this.setStylesOptions.bind(this), true);
    }

    private applyAfterRender = (fn: () => void) => {
        this.clearStylesTimeout();

        this.setStylesTimeout = setTimeout(() => fn());
    };

    private clearStylesTimeout(): void {
        if (this.setStylesTimeout) {
            clearTimeout(this.setStylesTimeout);
        }
    }

    ngOnDestroy() {
        this.hideOptions();
        this.clearStylesTimeout();
        this.removeScrollListener();
    }
}
