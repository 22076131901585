import { ValidationErrors } from '@angular/forms';

export class FormControlsService {
    private readonly errorMessages = {
        required: 'Field is required.',
        email: 'Please enter valid email address.',
        pattern: 'Please enter valid value.',
        not_unique: 'This value is already taken.',
        not_unique_email:
            'Email Address is Already Registered. Use Sign in link to access your existing account',
        minlength: 'Min length is {X} symbols.',
        invalidName: 'Please provide a valid name.',
        invalidValue: 'Invalid value entered.',
        invalidZipcode: 'Zipcode is invalid.',
        invalidLanguage: 'Invalid symbols are used.',
        invalidPhoneNumber: 'Phone number is invalid.',
        invalidSsn: 'Ssn is invalid. Correct format XXX-XX-XXXX',
        invalidDateValue: 'Invalid date value.',
        invalidDateFormat: 'Invalid date format.',
        invalidDateRange: 'Invalid date range.',
        invalidMinDate: 'Invalid minimum date value.',
        maxlength: 'Max length is {X} symbols.',
        incorrect: 'Incorrect value.',
        zipCode: 'ZIP invalid. Correct format 00000 or 00000-0000.',
        socialSecurityNumber: 'Field value is invalid. Correct format 000-00-0000.',
        verificationCodeChecksLimit: 'Wrong code. Please Resend the code and try again.',
        verificationCodeError: 'Wrong code. Please try again.',
        zipCodeNotSupported: 'Zipcode value is incorrect.',
        allergyAlreadyAdded: 'Allergy is already added.',
        invalidInsuranceNumber: 'Please provide a valid insurance ID.',
        whitespace: 'Field cannot consist of whitespace only.',
        notAutocompleteOption: "Not acceptable value. Please select from the list.",
        min: 'Value can\'t be less than {X}.',
        max: 'Value can\'t exceed {X}.',
        mask: 'Please enter a valid value.',
        inchesLimitExceeded: 'Should be between 0 and 11.',
        feetRequired: 'Please enter your height in feet.',
        heightRequired: 'Please enter your height in feet and inches.',
        heightTooShort: 'The shortest height we can accept is 1 foot 9.5 inches. Either there’s a typo or you’re a new Guinness record holder!',
        heightTooTall: 'The tallest height we can accept is 9 feet 0 inches. Either there’s a typo or you’re a new Guinness record holder!',
        maxWeightValue: 'Max 1400 pounds (max recorded weight for a human).'
    };

    public getErrorMessage(errors: ValidationErrors): string {
        if (!errors) return '';

        return Object.keys(errors || [])
            .map((code) => {
                if (['maxlength', 'minlength'].includes(code)) {
                    return this.errorMessages[code].replace('{X}', errors[code].requiredLength);
                }

                if (code == 'min') {
                    return this.errorMessages[code].replace('{X}', errors[code].min);
                }

                if (code == 'max') {
                    return this.errorMessages[code].replace('{X}', errors[code].max);
                }

                return this.errorMessages[code] || null;
            })
            .join(' ');
    }
}
