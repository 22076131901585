import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ISelectOption } from '@shared/components/form-controls';
import { DropDownControlDirective } from '@shared/components/form-controls/directives/drop-down-directive/drop-down-form-control.directive';
import { IControlBadge } from '@shared/components/form-controls/interfaces/badge.interface';
import { EDataTestIdKeys, getDataTestId } from '@shared/utils';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'mv-select-form-control',
    templateUrl: './select-form-control.component.html',
    styleUrls: ['./select-form-control.component.scss'],
    host: { class: 'mv-control' },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFormControlComponent implements OnInit, OnDestroy {
    @Input() label: string = '';
    @Input() testId: string = '';
    @Input() control: FormControl = new FormControl('');
    @Input() disabled: boolean = false;
    @Input() loading = false;

    @Input() badge: IControlBadge | null = null;

    @Input() withCustomTemplate = false;

    @Input() optionsContainerStyles: { [key: string]: string } = {};

    private optionsMap = new Map<any, string>();
    private optionsBadges = new Map<any, IControlBadge>();

    @Input() set options(options: ISelectOption[]) {
        this.optionsItems = options;

        this.optionsMap.clear();
        this.optionsBadges.clear();

        this.optionsDataTestIds = options?.map((option) => {
            return getDataTestId(EDataTestIdKeys.OPTION, option.text);
        });

        options?.forEach((option) => {
            if (!!option.value) {
                this.optionsMap.set(option.value, option.text);
            }

            if (!!option.badge) {
                this.optionsBadges.set(option.value, option.badge);
            }
        });
    }

    @Output() optionSelected = new EventEmitter<ISelectOption>();

    @HostBinding('class.mv-control-invalid') get errorState(): boolean {
        return this.control.touched && this.control.invalid;
    }

    @ViewChild(DropDownControlDirective) dropdownControlDirective!: DropDownControlDirective;

    public optionsItems: ISelectOption[] = [];
    public isOpenOptions: boolean = false;

    // Data Test IDs
    public selectDataTestId: string;
    public optionsDataTestIds: string[];

    private destroy$ = new Subject<void>();

    public get isDisabled(): boolean {
        return this.disabled || this.control.disabled;
    }

    public get isValueSet(): boolean {
        return this.control.value !== undefined && this.control.value !== null;
    }

    public get isRequired(): boolean {
        return this.control.hasValidator(Validators.required);
    }

    public get optionText(): string {
        return this.optionsMap.get(this.control.value) || this.control.value;
    }

    public get optionBadge(): IControlBadge | null {
        return this.optionsBadges.get(this.control.value) || this.badge || null;
    }

    public get isError(): boolean {
        return this.control.invalid && this.control.touched;
    }

    // не удалять, плохо работает индексация,
    // используется при обращении к elementRef селекта
    public get isDropDownOpened(): boolean {
        return !!this.dropdownControlDirective?.isOptionsOpened;
    }

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.setDataTestId();
        this.subscribeValueAndStatusChanges();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onOpenOptions(): void {
        this.isOpenOptions = true;
    }

    public onCloseOptions(): void {
        this.isOpenOptions = false;
    }

    public onSelectOption(option: ISelectOption): void {
        this.optionSelected.emit(option);

        this.control.markAsDirty();
        this.control.setValue(option.value);
    }

    private setDataTestId(): void {
        this.selectDataTestId = getDataTestId(EDataTestIdKeys.SELECT, this.testId || this.label);
    }

    private subscribeValueAndStatusChanges(): void {
        merge(this.control.valueChanges, this.control.statusChanges)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.cdr.markForCheck());
    }
}
