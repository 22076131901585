export enum AutocompleteAttrEnum {
    Off = 'off',
    On = 'on',
    GivenName = 'given-name',
    FamilyName = 'family-name',
    OneTimeCode = 'one-time-code',
    PostalCode = 'postal-code',
    BirthDay = 'bday',
    Sex = 'sex',
    Tel = 'tel-national',
    Email = 'email',
    Date = 'date',
}
