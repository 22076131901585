<div class="date-control" (click)="openDatepicker(picker)">
    <div class="date-control-container" [class.disabled]="isDisabled" [class.error]="isError">
        <div
            *ngIf="label"
            class="date-control__label"
            [class.skipAnimation]="true"
            [class.floated]="control.value"
            [class.required]="isRequired"
        >
            {{ label }}
        </div>

        <input
            readonly
            class="date-control__value"
            [formControl]="control"
            [min]="minDate"
            [max]="maxDate"
            [matDatepicker]="picker"
            [attr.data-test-id]="dateDataTestId"
        />

        <mv-google-symbol-icon class="date-control__icon state-icon">
            calendar_month
        </mv-google-symbol-icon>

        <mv-control-badge
            *ngIf="badge"
            class="date-control__badge state-icon"
            [badge]="badge"
        ></mv-control-badge>
    </div>

    <mat-datepicker #picker></mat-datepicker>

    <mv-control-error-message
        [isError]="isError"
        [errors]="control.errors"
    ></mv-control-error-message>
</div>
