import {
    HTTP_INTERCEPTORS,
    HttpErrorResponse,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { captureMessage } from '@sentry/browser';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { INITIALIZER, OPTIONS, SentryModuleOptions } from './sentry.config';

@Injectable()
class SentryErrorInterceptor implements HttpInterceptor {
    constructor(@Inject(OPTIONS) private options: SentryModuleOptions) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(request.clone()).pipe(
            catchError((response) => {
                // log to Sentry
                if (response instanceof HttpErrorResponse && this.filter(response)) {
                    captureMessage(this.getMessage(request, response));
                }

                // re-throw error
                return throwError(() => response);
            })
        );
    }

    private filter(response: HttpErrorResponse): boolean {
        const error = response?.error?.error;

        if (!this.options.enabled) {
            return false;
        }

        if (!this.options.http || this.options.http.enabled === false) {
            return false;
        }

        if (error?.payload?.debugMessage === 'User not found') return false;

        // apply whitelist
        if (this.options.http.whitelist && this.options.http.whitelist.length > 0) {
            return this.options.http.whitelist.includes(response.status);
        }

        // apply blacklist
        if (this.options.http.blacklist && this.options.http.blacklist.length > 0) {
            return !this.options.http.blacklist.includes(response.status);
        }

        return true;
    }

    // Builds the message for capturing.
    private getMessage(request: HttpRequest<any>, response: HttpErrorResponse): string {
        const defaultMessage = 'Http request failed. ({method}, {status}, {url})';
        const replace = (msg: string): string => {
            const map: Record<string, string | number> = {
                method: request.method,
                url: request.url,
                status: response.status,
                message: response.message,
            };

            // replace all keys with their values
            Object.keys(map).forEach((key) => {
                msg = msg.replace(new RegExp(`{${key}}`, 'g'), <string>map[key]);
            });

            return msg;
        };

        // use custom message
        if (this.options.http && this.options.http.message) {
            return replace(this.options.http.message);
        }

        // use default message
        return replace(defaultMessage);
    }
}

export const SentryErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: SentryErrorInterceptor,
    deps: [OPTIONS, INITIALIZER],
    multi: true,
};
