import { NgForOf, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input, OnDestroy,
    OnInit
} from '@angular/core';
import {
    FormControl,
    Validators
} from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { SelectFormFieldOption } from '@patient/app/shared/interfaces/select-form-field-option.interface';
import { ErrorTextPipeModule } from '../../../services/error-text/error-text-pipe.module';
import { FormControlsModule } from '@shared/components/form-controls';
import { filter, Subject, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    ChildControlValidator
} from '@patient/app/shared/validators/child-control/child-control.validator';

export enum EPlainSelectDirections {
    VERTICAL,
    HORIZONTAL
}

const OTHER_OPTION_VALUE = '';

@Component({
    selector: 'app-plain-select',
    templateUrl: './plain-select.component.html',
    styleUrls: ['./plain-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgForOf, NgIf, ErrorTextPipeModule, MatRippleModule, MatRadioModule, FormControlsModule],
    standalone: true,
})
export class PlainSelectComponent implements OnInit, OnDestroy {
    @Input() options: SelectFormFieldOption[] = [];
    @Input() direction: EPlainSelectDirections = EPlainSelectDirections.VERTICAL;
    @Input() withOtherOption: boolean = false;
    @Input() control: FormControl;

    public radioGroupOptions: SelectFormFieldOption[] = [];
    public otherOptionFromControl = new FormControl<string>('', Validators.maxLength(255));

    private destroy$ = new Subject<void>();

    public get isHorizontalSelect(): boolean {
        return this.direction === EPlainSelectDirections.HORIZONTAL;
    }

    public get isOtherOptionSelected(): boolean {
        const selectedOption = this.options.find(elem => elem.value === this.control.value);
        return !selectedOption && this.control.value !== null;
    }

    public get isFieldInvalid(): boolean {
        return this.control.errors && this.control.touched;
    }

    public ngOnInit(): void {
        this.calculateOptionsList();
        this.handleOtherOptionFormControl();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public getFormControlValue(): string | number {
        return this.isOtherOptionSelected ? OTHER_OPTION_VALUE : this.control.value;
    }

    public change(value: any): void {
        const newValue = value !== this.control.value ? value : undefined;
        this.control.setValue(newValue);
        if (value === '') {
            this.otherOptionFromControl.setValue('');
        } else {
            this.otherOptionFromControl.setValue(null);
            this.control.updateValueAndValidity();
        }

        this.control.markAsTouched();
        this.otherOptionFromControl.markAsTouched();
    }

    private calculateOptionsList(): void {
        this.radioGroupOptions = this.withOtherOption ? [
            ...this.options,
            {
                value: OTHER_OPTION_VALUE,
                label: 'Other'
            }
        ] : this.options;
    }

    private handleOtherOptionFormControl(): void {
        if (!this.withOtherOption) return;

        this.control.addValidators(ChildControlValidator(this.otherOptionFromControl));
        this.checkIfOtherOptionSet();

        this.otherOptionFromControl.valueChanges
            .pipe(
                filter(val => val !== null),
                tap(value => this.control.setValue(value)),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    private checkIfOtherOptionSet(): void {
        if (!this.isOtherOptionSelected) {
            return;
        }

        this.otherOptionFromControl.setValue(this.control.value);
    }
}
