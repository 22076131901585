<div class="select-form-control" [attr.data-test-id]="selectDataTestId">
    <div
        controlDropDownOptions
        class="select-form-control-container"
        [class.disabled]="isDisabled"
        [class.error]="isError"
        [optionsTemplate]="optionsTemplate"
        (openOptions)="onOpenOptions()"
        (closeOptions)="onCloseOptions()"
    >
        <div
            *ngIf="label"
            class="select-form-control__label"
            [class.skipAnimation]="true"
            [class.floated]="isValueSet"
            [class.required]="isRequired"
        >
            {{ label }}
        </div>

        <div
            *ngIf="isValueSet"
            [class.no-label]="!label"
            class="select-form-control__value"
        >
            {{ optionText }}
        </div>

        <mv-control-badge
            class="select-form-control__badge state-icon"
            *ngIf="optionBadge"
            [badge]="optionBadge"
        ></mv-control-badge>

        <mv-google-symbol-icon *ngIf="!loading" class="select-form-control__icon state-icon" [class.open]="isOpenOptions">
            chevron_right
        </mv-google-symbol-icon>

        <!--  Loading Icon -->
        <mv-google-symbol-icon *ngIf="loading" class="select-form-control__loading state-icon" [size]="20">
            progress_activity
        </mv-google-symbol-icon>

        <ng-template #optionsTemplate>
            <div
                class="select-form-control-options"
                [ngStyle]="optionsContainerStyles"
                [class.no-items]="!optionsItems?.length && !withCustomTemplate"
            >
                <div
                    *ngFor="let optionItem of optionsItems; index as i"
                    class="select-form-control__option"
                    [class.selected]="optionText === optionItem.text"
                    [attr.data-test-id]="optionsDataTestIds[i]"
                    (click)="onSelectOption(optionItem)"
                >
                    <div>{{ optionItem.text }}</div>

                    <mv-control-badge
                        class="select-form-control__badge"
                        *ngIf="optionItem.badge"
                        [badge]="optionItem.badge"
                    ></mv-control-badge>
                </div>
                <div *ngIf="withCustomTemplate" class="select-form-control__custom-template">
                    <ng-content select="[mv-custom-template]"></ng-content>
                </div>
            </div>
        </ng-template>
    </div>
    <mv-control-error-message
        [isError]="isError"
        [errors]="control.errors"
    ></mv-control-error-message>
</div>
