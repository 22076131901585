import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { FormControlsService } from '../../services/form-controls.service';

@Component({
    selector: 'mv-control-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorMessageComponent {
    @Input() isError: boolean = false;
    @Input() errors: ValidationErrors | null = null;

    constructor(public formControlsService: FormControlsService) {}
}
