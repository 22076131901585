import { ModuleWithProviders, NgModule } from '@angular/core';
import { OPTIONS, SentryModuleOptions } from './sentry.config';
import { SentryErrorHandlerProvider } from './sentry.handler';
import { SentryInitializerProvider } from './sentry.initializer';
import { SentryErrorInterceptorProvider } from './sentry.interceptor';

@NgModule({ imports: [], declarations: [], exports: [] })
export class SentryModule {
    public static forRoot(options: SentryModuleOptions): ModuleWithProviders<SentryModule> {
        return {
            ngModule: SentryModule,
            providers: [
                SentryInitializerProvider,
                SentryErrorHandlerProvider,
                SentryErrorInterceptorProvider,
                { provide: OPTIONS, useValue: options },
            ],
        };
    }
}
