<div class="textarea-control">
    <div
        class="textarea-control-container"
        [style.height]="height ?? 'auto'"
        [class.has-badge]="!!badge"
        [class.disabled]="isDisabled"
        [class.error]="isError"
        (click)="onFocus()"
    >
        <!-- Label -->
        <div
            *ngIf="label"
            class="textarea-control__label"
            [class.required]="isRequired"
            [class.floated]="isFocus || control.value"
        >
            {{ label }}
        </div>

        <!-- Textarea -->
        <textarea
            #textarea
            class="textarea-control__value"
            [class.no-label]="!label"
            [style.overflow-y]="overflow"
            [formControl]="control"
            [rows]="rows"
            [placeholder]="placeHolderValue"
            [attr.data-test-id]="textareaDataTestId"
            (input)="autoresizeTextarea()"
            (focus)="onFocus()"
            (blur)="onBlur()"
            (keydown.enter)="stopKeyEnterPropagation($event)"
        ></textarea>

        <!-- Badge -->
        <mv-control-badge
            *ngIf="badge"
            class="textarea-control__badge"
            [badge]="badge"
        ></mv-control-badge>

        <div *ngIf="withCustomTemplate" class="textarea-control__custom-template">
            <ng-content select="[mv-custom-template]"></ng-content>
        </div>
    </div>

    <!-- Error Message -->
    <mv-control-error-message
        [isError]="isError"
        [errors]="control.errors"
    ></mv-control-error-message>
</div>
