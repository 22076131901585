export enum EQueryParameters {
    SERVICE = 'service',
    PROMO_CODE = 'promocode',
    AUTO_SIGN_IN_EMAIL = 'autoSignInEmail',
    AUTO_SIGN_IN_PHONE = 'autoSignInPhone',
    NEXT_VISIT_ACTION = 'nextVisitAction',
    RESCHEDULE = 'reschedule',
    G_CLIENT_ID = 'gClientId',
    UTM_SOURCE = 'utm_source',
    UTM_MEDIUM = 'utm_medium',
    UTM_CAMPAIGN = 'utm_campaign',
    UTM_CONTENT = 'utm_content',
    UTM_TERM = 'utm_term',
    INTAKE_REMINDER_ATTEMPT = 'intakeReminderAttempt',
    INTAKE_REMINDER_SOURCE = 'intakeReminderSource'
}

export enum ELocalStorageKeys {
    G_CLIENT_ID = 'G_CLIENT_ID',
    UTM_SOURCE = 'UTM_SOURCE',
    UTM_MEDIUM = 'UTM_MEDIUM',
    UTM_CAMPAIGN = 'UTM_CAMPAIGN',
    UTM_CONTENT = 'UTM_CONTENT',
    UTM_TERM = 'UTM_TERM',
    PROMO_CODE = 'PROMOCODE',
    SELECTED_SERVICES = 'SELECTED_SERVICES',
    AUTO_SIGN_IN_EMAIL = 'AUTO_SIGN_IN_EMAIL',
    AUTO_SIGN_IN_PHONE = 'AUTO_SIGN_IN_PHONE',
    DISCOUNT_IS_CLOSED_BY_USER = 'DISCOUNT_IS_CLOSED_BY_USER',
    API_AUTH_TOKEN = 'API_AUTH_TOKEN',
    API_AUTH_REFRESH_TOKEN = 'API_AUTH_REFRESH_TOKEN',
    I_WAS_AUTHORIZED = 'I_WAS_AUTHORIZED',
    API_AUTH_TOKEN_EXP = 'API_AUTH_TOKEN_EXP',
    INTAKE_STEP_VALUES = 'INTAKE_STEP_VALUES',
    INTAKE_REMINDER_ATTEMPT = 'intakeReminderAttempt',
    INTAKE_REMINDER_SOURCE = 'intakeReminderSource'
}
