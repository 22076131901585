import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IControlBadge } from '@shared/components/form-controls';
import { EDataTestIdKeys, getDataTestId } from '@shared/utils';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'mv-textarea-form-control',
    templateUrl: './textarea-form-control.component.html',
    styleUrls: ['./textarea-form-control.component.scss'],
    host: { class: 'mv-control' },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaFormControlComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() label: string = '';
    @Input() testId: string = '';
    @Input() control: FormControl = new FormControl('');
    @Input() placeholder: string = '';
    @Input() height: string;
    @Input() rows: number | null = 4;
    @Input() disabled: boolean = false;

    @Input() badge: IControlBadge | null = null;

    @Input() withCustomTemplate = false;

    @ViewChild('textarea') textarea!: ElementRef;

    @HostBinding('class.mv-control-invalid') get errorState(): boolean {
        return this.control.touched && this.control.invalid;
    }

    public get placeHolderValue(): string {
        return this.placeholder || '';
    }

    public get overflow(): 'auto' | 'none' {
        return this.height ? 'auto' : 'none';
    }

    public isFocus: boolean = false;

    // Data Test ID
    public textareaDataTestId: string;

    private destroy$ = new Subject<void>();

    public get isDisabled(): boolean {
        return this.disabled || this.control.disabled;
    }

    public get isError(): boolean {
        return this.control.invalid && this.control.touched;
    }

    public get isRequired(): boolean {
        return this.control.hasValidator(Validators.required);
    }

    constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.setDataTestId();
        this.subscribeValueAndStatusChanges();
    }

    ngAfterViewInit(): void {
        this.autoresizeTextarea();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onFocus(): void {
        this.isFocus = true;
        this.textarea?.nativeElement?.focus();
    }

    public onBlur(): void {
        this.isFocus = false;
        this.textarea?.nativeElement?.blur();
    }

    public stopKeyEnterPropagation(event: KeyboardEvent): void {
        event.stopPropagation();
    }

    // resize textarea automatically on input event
    public autoresizeTextarea(): void {
        if (this.height) return;

        const textareaEl = this.textarea.nativeElement;

        this.renderer.setStyle(textareaEl, 'height', 'auto');
        this.renderer.setStyle(textareaEl, 'height', `${textareaEl.scrollHeight}px`);
    }

    private setDataTestId(): void {
        this.textareaDataTestId = getDataTestId(
            EDataTestIdKeys.TEXTAREA,
            this.testId || this.label
        );
    }

    private subscribeValueAndStatusChanges(): void {
        merge(this.control.valueChanges, this.control.statusChanges)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.autoresizeTextarea();
                this.cdr.markForCheck();
            });
    }
}
