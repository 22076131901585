<div
    [class.invalid]="isFieldInvalid"
    class="select-container"
>
    <mat-radio-group
        [value]="getFormControlValue()"
        [class.horizontal]="isHorizontalSelect"
        (change)="change($event.value)"
        color="primary"
        class="form-container"
    >
        <mat-radio-button
            *ngFor="let option of radioGroupOptions"
            class="option"
            [value]="option.value"
            [class.selected]="control.value === option.value"
            [attr.data-test-id]="'radio-button-' + option.label"
        >
            {{ option.label }}
        </mat-radio-button>
    </mat-radio-group>
    <mv-input-form-control
        *ngIf="isOtherOptionSelected"
        [placeholder]="'Example...'"
        [control]="otherOptionFromControl"
        class="input-form"
    ></mv-input-form-control>
</div>
<mv-control-error-message
    [isError]="isFieldInvalid"
    [errors]="control.errors"
></mv-control-error-message>
