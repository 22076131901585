import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DropDownControlDirective } from '@shared/components/form-controls/directives/drop-down-directive/drop-down-form-control.directive';
import { NgxMaskModule } from 'ngx-mask';
import { GoogleSymbolIconComponent } from '../google-symbol-icon/google-symbol-icon.component';
import { ControlBadgeComponent } from './components/control-badge/control-badge.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { AutocompleteFormControlComponent } from './controls/autocomplete-form-control/autocomplete-form-control.component';
import { ButtonToggleComponent } from './controls/button-toggle/button-toggle.component';
import { CheckboxFormControlComponent } from './controls/checkbox-form-control/checkbox-form-control.component';
import { DateFormControlComponent } from './controls/date-form-control/date-form-control.component';
import { InputFormControlComponent } from './controls/input-form-control/input-form-control.component';
import { SelectFormControlComponent } from './controls/select-form-control/select-form-control.component';
import { SwitchComponent } from './controls/switch/switch.component';
import { TextareaFormControlComponent } from './controls/textarea-form-control/textarea-form-control.component';
import { FormControlsService } from './services/form-controls.service';

@NgModule({
    declarations: [
        ControlBadgeComponent,
        InputFormControlComponent,
        SelectFormControlComponent,
        DateFormControlComponent,
        TextareaFormControlComponent,
        DropDownControlDirective,
        CheckboxFormControlComponent,
        ErrorMessageComponent,
        AutocompleteFormControlComponent,
        ButtonToggleComponent,
        SwitchComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatDatepickerModule,
        MatCardModule,
        NgxMaskModule,
        TextFieldModule,
        GoogleSymbolIconComponent,
    ],
    exports: [
        InputFormControlComponent,
        SelectFormControlComponent,
        DateFormControlComponent,
        CheckboxFormControlComponent,
        TextareaFormControlComponent,
        AutocompleteFormControlComponent,
        ErrorMessageComponent,
        ButtonToggleComponent,
        SwitchComponent
    ],
    providers: [FormControlsService],
})
export class FormControlsModule {}
