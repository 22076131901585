import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';

export function ChildControlValidator(childControl: FormControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: object } | null => {
        const { touched, dirty } = control;

        if (!(dirty || touched)) return null;

        return childControl.valid ? null : { childControlInvalid: {} };
    };
}
