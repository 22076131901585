import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { EDataTestIdKeys, getDataTestId } from '@shared/utils';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutocompleteAttrEnum, IControlBadge } from '../../interfaces';

@Component({
    selector: 'mv-date-form-control',
    templateUrl: './date-form-control.component.html',
    styleUrls: ['./date-form-control.component.scss'],
    host: { class: 'mv-control' },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFormControlComponent implements OnInit, OnDestroy {
    @Input() label: string = '';
    @Input() testId: string = '';
    @Input() control: FormControl = new FormControl('');
    @Input() disabled: boolean = false;
    @Input() minDate: string | null = null;
    @Input() maxDate: string | null = null;
    @Input() autocompleteAttrName = AutocompleteAttrEnum.Off;

    @Input() badge: IControlBadge | null = null;

    @HostBinding('class.mv-control-invalid') get errorState(): boolean {
        return this.control.touched && this.control.invalid;
    }

    // Data Test ID
    public dateDataTestId: string;

    private destroy$ = new Subject<void>();

    public get isDisabled(): boolean {
        return this.disabled || this.control.disabled;
    }

    public get isRequired(): boolean {
        return this.control.hasValidator(Validators.required);
    }

    public get isError(): boolean {
        return this.control.invalid && this.control.touched;
    }

    constructor(private cdr: ChangeDetectorRef, public elementRef: ElementRef) {}

    ngOnInit(): void {
        this.setDataTestId();
        this.subscribeValueAndStatusChanges();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public openDatepicker(picker: MatDatepicker<any>): void {
        if (this.disabled) return;
        picker.open();
    }

    private setDataTestId(): void {
        this.dateDataTestId = getDataTestId(EDataTestIdKeys.DATE, this.testId || this.label);
    }

    private subscribeValueAndStatusChanges(): void {
        merge(this.control.valueChanges, this.control.statusChanges)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.cdr.markForCheck());
    }
}
